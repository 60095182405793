<template>
  <div class="content-wrapper">
    <page-header
      screen-name="Ver usuários"
      :link-items="linkItems"
    />

    <overlay
      :show="loading"
      class-name="card hidden_overflow_tabela"
    >
      <HeaderEmpresa
        :empresa="empresa"
        @confirmed="resolveRoute"
      />

      <div
        class="card"
      >
        <b-row class="px-2">
          <b-col
            sm="12"
            md="5"
          >
            <div
              class="d-flex justify-center-center align-items-center"
            >
              <span class="mr-50">Mostrar</span>
              <v-select
                id="orders"
                v-model="paginationData.defaultSize"
                :options="table.tableRows"
                :clearable="false"
                :disabled="!showTable"
                @input="updateQtdView($event)"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
            </div>
          </b-col>

          <b-col
            sm="12"
            md="7"
            class="mb-1"
          >
            <b-form-group>
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  id="search"
                  v-model="search.nameOrEmail"
                  class="form-control-merge"
                  placeholder="Buscar"
                  autocomplete="off"
                  @keyup="handleSearchByKeyUp"
                />

                <b-input-group-append
                  v-if="search.nameOrEmail"
                  is-text
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    @click="clearFiltersAndFindAll"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="loadingTable">
          <b-col cols="12">
            <div
              v-if="table.tableBusy"
              class="spinner-area"
            >
              <b-spinner class="spinner-border text-custom-blue" />
            </div>
          </b-col>
        </b-row>

        <b-row
          v-if="showTable"
        >
          <b-col cols="12">
            <b-alert
              variant="primary"
              :show="table.tableError"
              class="mx-2 mt-5"
            >
              <div class="alert-body d-flex justify-content-center">
                <span class="text-primary">
                  <strong
                    class="text-primary"
                  >Sistema de busca indisponível no momento.</strong>
                </span>
              </div>
            </b-alert>
          </b-col>

          <b-col
            cols="12"
            class="mb-2 zindex-0"
          >
            <b-table
              responsive
              sticky-header="380px"
              :busy.sync="table.tableBusy"
              :no-local-sorting="true"
              :fields="table.fields"
              :items="table.items"
              @context-changed="handleOrderTable"
            >
              <template #cell(nome)="row">
                <span>{{ row.value }}</span>
              </template>

              <template #cell(email)="row">
                <span>{{ row.value }}</span>
              </template>

              <template #cell(ativo)="row">
                <b-form-checkbox
                  v-model="row.value"
                  class="custom-control-success"
                  name="check-button"
                  :disabled="switchDisabled"
                  switch
                  @change="defineStatusUser(row)"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </template>

              <template #cell(actions)="row">
                <button-icon
                  color="#2772C0"
                  size="18"
                  class-name="m-button-icon"
                  feather-icon="EyeIcon"
                  @action="redirectViewPage(row.item)"
                />
                <button-icon
                  color="#2772C0"
                  size="18"
                  feather-icon="EditIcon"
                  @action="redirectUpdatePage(row.item)"
                />
              </template>
            </b-table>
          </b-col>

          <b-col
            class="px-3"
            sm="12"
          >
            <CustomPagination
              :paginacao="paginationData"
              @page-cliked="updateCurrentPage"
            />
          </b-col>
        </b-row>
      </div>
    </overlay>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BTable,
  BAlert,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'

import { required, email } from '@validations'
import vSelect from 'vue-select'
import { getIdEmpresa } from '@core/utils/store/getStore'
import { setIdEmpresa } from '@core/utils/store/setStore'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ButtonIcon from '@/views/components/custom/Buttons/ButtonIcon/ButtonIcon.vue'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import { getCompanyUsers, updateStatusCompanyUser } from '@/views/custom-pages/gerenciarUsuarios/requests'
import HeaderEmpresa from '@/views/components/custom/page-header/Header-empresa.vue'
import { warningMessage } from '@/libs/sweetalerts'

export default {
  components: {
    HeaderEmpresa,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BTable,
    BAlert,
    BSpinner,
    BFormCheckbox,
    Overlay,
    ButtonIcon,
    vSelect,
    PageHeader,
    CustomPagination,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Empresa',
          routeName: 'empresa-usuario-list',
        },
        {
          name: 'Ver empresa',
          active: true,
        },
        {
          name: '...',
          active: true,
        },
      ],

      loading: false,
      loadingTable: false,

      empresa: {
        id_empresa: '',
        nome: '',
        gerenciarColaboradores: false,
      },

      required,
      email,
      titlePage: '',

      search: {
        nameOrEmail: '',
      },

      showTable: false,
      switchDisabled: false,

      paginationData: {
        currentPage: 0,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },

      table: {
        empty: false,
        tableError: false,
        tableBusy: true,
        tableRows: [10, 25, 50, 100],
        tableOrder: '',
        tableColumnNameOrder: '',
        fields: [
          { key: 'nome', label: 'NOME', sortable: true },
          { key: 'email', label: 'E-MAIL', sortable: true },
          { key: 'ativo', label: 'STATUS', sortable: true },
          {
            key: 'actions',
            label: 'AÇÕES',
            class: 'width-custom-column text-center',
          },
        ],
        items: [],
      },
    }
  },

  mounted() {
    if ((getIdEmpresa() === null || typeof getIdEmpresa() === 'undefined') && typeof this.$route.params.idEmpresa === 'undefined') {
      this.openModalError()
      return
    }

    if (typeof this.$route.params.idEmpresa !== 'undefined') {
      if (this.$route.params.idEmpresa !== getIdEmpresa()) {
        setIdEmpresa(this.$route.params.idEmpresa)
      }
    }

    this.index()
  },

  methods: {
    async index() {
      await this.handleGetCompany()

      this.loadingTable = true

      this.findAll()
    },

    async handleGetCompany() {
      this.loading = true

      await this.$http.get(this.$api.getShowAndEditEmpresa(getIdEmpresa())).then(({ data }) => {
        this.empresa.id_empresa = data.id_empresa
        this.empresa.nome = data.cnpj ? data.nome_fantasia : data.nome
        this.empresa.gerenciarColaboradores = data.pode_gerenciar_colaboradores
        this.linkItems[2].name = this.empresa.nome
      })

      this.loading = false
    },

    handleSearchByKeyUp() {
      if (this.search.nameOrEmail.length === 0 || this.search.nameOrEmail.length >= 3) {
        this.findAll()
      }
    },

    findAll() {
      this.table.tableBusy = true

      this.table.tableError = false
      this.table.empty = false

      getCompanyUsers(this.getParams())
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.length > 0) {
              this.showTable = true
              this.table.items = response.data.data
              this.table.tableBusy = false
              this.handlePagination(response.data)
              return
            }

            this.table.tableBusy = false
            this.table.empty = true
            this.showTable = false
          }
        })
        .catch(() => {
          this.table.tableError = true
          this.showTable = false
          this.table.tableBusy = false
        })
    },

    async defineStatusUser({ item }) {
      this.switchDisabled = true

      await updateStatusCompanyUser(item.id_usuario)
        .catch(() => {
          warningMessage('Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.')
        })

      this.switchDisabled = false
    },

    redirectViewPage({ id_usuario }) {
      this.$router.push(
        {
          name: 'empresa-usuario-show',
          params: {
            idUsuario: id_usuario,
            empresa: this.empresa,
          },
        },
      )
    },

    redirectUpdatePage({ id_usuario }) {
      this.$router.push(
        {
          name: 'empresa-usuario-edit',
          params: {
            idUsuario: id_usuario,
            empresa: this.empresa,
          },
        },
      )
    },

    handleSubmitFormFilters() {
      this.$refs.formFilters.validate()
        .then(success => {
          if (success) {
            this.findAll()
          }
        })
    },

    clearFilters() {
      this.search.nameOrEmail = ''
    },

    clearFiltersAndFindAll() {
      this.search.nameOrEmail = ''

      this.findAll()
    },

    handleOrderTable(context) {
      this.table.tableColumnNameOrder = context.sortBy
      this.table.tableOrder = context.sortDesc ? 'desc' : 'asc'

      this.findAll()
    },

    getParams() {
      return {
        colunaNome: this.table.tableColumnNameOrder,
        colunaOrdem: this.table.tableOrder,
        porPagina: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,
        nomeOuEmail: this.search.nameOrEmail,
        empresaId: this.empresa.id_empresa,
      }
    },

    handlePagination(data) {
      this.paginationData.fromLine = data.from
      this.paginationData.toLine = data.to
      this.paginationData.totalLines = data.total
      this.paginationData.currentPage = data.current_page
    },

    updateQtdView(event) {
      if (!event) {
        this.paginationData.defaultSize = 10
      }

      this.paginationData.currentPage = 1
      this.findAll()
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.findAll()
    },

    resolveRoute(route) {
      if (route !== this.$route.name) {
        this.$router.push(
          {
            name: route,
            params: {
              empresa: this.empresa,
            },
          },
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.action-search {
  display: flex;
  margin-top: 6px;
}

.m-button-icon {
  margin-right: 5px;
}

@media (max-width: 400px) {
  .action-search {
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
    }
  }

  .button-plus {
    width: 100%;
  }
}
</style>
